import { useQuery } from "react-query"
import { CLIENT_SOURCES, getFunctionalAreas, STATIC_FN_AREAS } from "../api/fetchers/functionalAreas"



export const useFunctionalAreas = (isDynamicFNA = false) => {
  const { data, isLoading } = useQuery(
    ['GET_SYSTEM_FUNCTIONAL_AREAS'],
    getFunctionalAreas,
    { enabled: isDynamicFNA }
  )

  return {
    data: isDynamicFNA ? data : STATIC_FN_AREAS,
    isLoading,
    clientSources: CLIENT_SOURCES
  }
}

//