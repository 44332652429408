import { SharedProfileData, ExpertiseID } from ".";
import { TLanguage } from "./Job";

export enum ProfileStatus {
  INCOMPLETE = "incomplete",
  COMPLETE = "complete",
}
export type TalentWork = {
  id?: string;
  link?: string;
  description: string;
  title: string;
  startedAt: string | null;
  startedAtMonth?: string | null;
  startedAtYear?: string | null;
  endedAt: string | null;
  endedAtMonth?: string | null;
  endedAtYear?: string | null;
  isCurrentlyWorkingHere?: boolean;
};
export interface TalentPortfolio {
  behanceLink: string | null;
  twitterLink: string | null;
  websiteLink: string | null;
  ytLink: string | null;
}
export type VideoConfirmationStatus = 'undecided' | 'denied' | 'verified';
export type YtVideo = {
  abvComments: string;
  abvLikes: string;
  abvViews: string;
  channelId: string;
  description?: string;
  channelThumbnail: string;
  confirmationStatus: VideoConfirmationStatus;
  channelTitle: string;
  abvChannelSubscribers: string | null;
  channelSubscribers: number;
  comments: number;
  // description: string;
  id: string;
  likes: number;
  publishedAt: string;
  thumbnail: string;
  title: string;
  views: number;
  url: string;
  functionalAreas: string[];
  clientSource: null | string;
};
export interface YtChannel {
  avatar: string;
  id: string;
  name: string;
  abvSubscribers: string;
  status: VideoConfirmationStatus;
  company?: null | {
    isVerified: boolean;
    id: string;
  }
}
export interface YoutubeVideosCollection {
  statistics: {
    abvComments: string;
    abvLikes: string;
    abvViews: string;
    counts: number;
    views?: number;
  };
  channels: YtChannel[];
  videos: YtVideo[];
  oldestNotVerifiedVideoDate?: string;
  oldestVerifiedVideoDate?: string;
}
export interface ICategory {
  name: string;
  id: string;
  isSystemAssigned: boolean;
}

export interface ISkills {
  name: string;
  id: string;
}
export interface IStyles {
  name: string;
  id: string;
}
export interface IReviews {
  body: string;
  rate: number;
  createdAt: Date;
  reviewable: TalentCardInfo;
  reviewer: {
    id: string;
    avatar: string;
    abvSubscribers: string;
    abvVideoCount: string;
    abvViews: string;
    name: string;
    ytLink: string;
    isVerified: boolean;
  }
}
export interface IBadges {
  redDiamond: number;
  blueDiamond: number;
  gold: number;
  silver: number;
  bronze: number;
  simpleVerified: number;
}
export interface TalentInfo extends SharedProfileData {
  about: string | null;
  applyCount?: number;
  functionalArea: ExpertiseID;
  isVerified?: boolean;
  hasVerifiedVideo?: boolean;
  hasEnabledHireMe: boolean;
  discord?: string;
  portfolio: TalentPortfolio;
  reasons?: string[] | null;
  profileStatus: ProfileStatus;
  works: TalentWork[];
  customTitle: string;
  youtubeVideos: YoutubeVideosCollection;
  categories: ICategory[];
  styles: IStyles[];
  skills: ISkills[];
  reviews: IReviews[];
  hasReviews?: boolean;
  vitrineImage?: string;
  vouchesCount: number;
  unreadNotificationCount?: number;
  outreachQuota?: number;
  pageViews?: string;
  latestVideoDate: string;
  phone?: null | string;
  createdAt?: string; // iso date
  linkYoutube?: null | string;
  linkTwitter?: null | string;
  emailVerified?: boolean | null; // null represents that item has no value but for false, has value but not verified.
  phoneVerified?: boolean | null;
  isYoutubeVerified?: boolean | null;
  isTwitterVerified?: boolean | null;
  isLocationVerified?: boolean | null;
  canChangeFunctionalArea?: boolean;
  badges?: IBadges;
  bestBadge?: null | keyof IBadges;
  bookingAvailable?: boolean;
  isBookable?: boolean;
  isQuickBookable?: boolean;
  quickBookingLink?: string;
  isPassSet?: boolean;
  languages?: TLanguage[];
  showReel?: YtVideo | null;
  headerImage?: string | File | null;
  canFlagPosts?: boolean;
  functionalAreas: {
    verified: number[];
    unverified: number[];
  };
}
export interface TalentApplicantInfo extends SharedProfileData {
  videoStatistics: {
    counts: number;
    verifiedVideosCount: number;
    abvViews: string;
    oldestVerifiedVideoDate: string;

  };
  topClient: {
    abvChannelSubscribers: string;
    channelThumbnail: string;
  };
  channels: {
    abvSubscribers: string;
    avatar: string;
    id: string;
    name: string;
    hasVerifiedApplicant: boolean;
  }[];
  functionalArea: ExpertiseID;
  isVerified?: boolean;
  hasEnabledHireMe: boolean;
  bestBadge?: null | keyof IBadges;
  languages: TLanguage[];
}
export type TalentCardInfo = Pick<
  TalentInfo,
  | "avatar"
  | "functionalArea"
  | "id"
  | "name"
  | "isVerified"
  | "hasEnabledHireMe"
  | "hasVerifiedVideo"
  | "bestBadge"
>;

export interface TalentProfile extends SharedProfileData {
  works: TalentWork[];
  about: string | null;
}
export enum VideoType {
  normal = 'Long-form',
  short = 'Short-form',
  all = 'No filter',
  'Long-form' = 'normal',
  'Short-form' = 'short',
  'No filter' = 'all',
}
export const ClientSize = {
  0: 'No filter',
  1: '1 - 10K',
  2: '10K - 100K',
  3: '100k - 1M',
  4: '+1M',
  '1 - 10K': '1',
  '10K - 100K': '2',
  '100k - 1M': '3',
  '+1M': '4',
  'No filter': undefined
}
export enum Availability {
  part_time = 'part-time',
  full_time = 'full-time',
  project_based = 'project-based',
}
export enum LocationType {
  remote = 'remote',
  hybrid = 'hybrid',
  onsite = 'onsite',
}
export interface TalentListReqFilter {
  functionalArea?: ExpertiseID;
  hasEnabledHireMe?: 'true';
  isBookable?: 'true';
  verifiedOnly?: 'true';
  videoType?: 'short' | 'normal';
  clientSize?: '1' | '2' | '3' | '4';
  niches?: string[];
  languages?: string[];
  country?: {
    id: string;
    location: string;
  };
  hasFilledForm?: 'true';
  temp?: string;
  bestBadge?: keyof IBadges;
  // this is just for request
  countryId?: string;
  availability?: Availability;
  locationType?: LocationType;
}
export type Talent = {
  id: string;
  name: string;
  functionalArea: ExpertiseID;
  avatar: string;
  isVerified: boolean;
};
////////report////////
export enum TalentReportReason {
  OTHERS_VIDEO = "OthersVideo",
  FALSE_INFORMATION = "FaleInformation",
  IMPERSONATES_ME = "ImpersonateMe",
  IMPERSONATES_OTHERS = "Impersonate_Others",
  BAD_EXPEIENCE = "BadExperience",
}
///////ٰVouch///////////
export type VouchedByChannel = {
  id: string;
  name: string;
  avatar: string;
  abvSubscribers: string;
  isVerified?: boolean;
};
export interface HireMeUpdateSetting {
  hire_me_availability_full_time: boolean;
  hire_me_availability_part_time: boolean;
  hire_me_availability_project_based: boolean;
  hire_me_location_remote: boolean;
  hire_me_location_hybrid: boolean;
  hire_me_location_hybrid_city: string | null;
  cityId?: string;
  locationDetails: {
    cityId: number | null;
    countryId: number | null;
    cityName: string | null;
    countryName: string | null;
  };
  salary_annual: null | number; // max:1_000_000|min:1
  salary_per_hour: null | number; //max:1000|min:1
  salary_per_project: null | number; // max:1_000_000|min:1
  salary_description: null | string; // max:512|min:5
  display_salary: boolean;
  hire_me: boolean;
  handle: null | string;

}
export type TReciveEmailStatus = 'instant' | 'once_per_day' | 'dnd';
export type TEmailNewApplicantStatus = 'once_per_week' | 'once_per_day' | 'dnd';
export type TalentSortPrefences = {
  column: string;
  direction: string;
}
export type TalentPrefences = {
  'videos.defaultSort': TalentSortPrefences | undefined;
}
export interface ITalentSetting extends HireMeUpdateSetting {
  quick_bookable: boolean;
  quick_booking_link: string | null;
  notifications: {
    emailMessages: TReciveEmailStatus;
    pushNotifications: boolean;
  };
  prefs: null | TalentPrefences;
}
export type ClaimedToWorkTalentAdditianlData = {
  phone: string;
  email: string;
  locationDetails: {
    cityId: number | null;
    countryId: number | null;
    cityName: string | null;
    countryName: string | null;
  };
}
export type ClaimedToWorkTalent = Pick<
  TalentInfo,
  | "hasEnabledHireMe"
  | "hasVerifiedVideo"
  | "isVerified"
  | "functionalArea"
  | "avatar"
  | "id"
  | "name"
  | "bestBadge"
> & ClaimedToWorkTalentAdditianlData;
export interface ClaimedToWorkVideo {
  description: string;
  thumbnail: string;
  status: "verified" | "denied" | "undecided";
  id: string;
  youtubeVideoId: string;
  title: string;
  abvLikes: string;
  abvViews: string;
  url: string;
  channelThumbnail: string;
  channelTitle: string;
  publishedAt: string;
  functionalAreas: string[];
}
export interface ICompany {
  id: string;
  name: string;
  avatar: string;
  abvSubscribers: string;
  description: string;
  ytLink: string;
  isVerified: boolean;
  isFeatured: number;
  jobsCount: number;
  subscribers: number;
}
export enum TalentSearchSort {
  ALL = 'All',
  WEEKLY = 'scoreWeekly',
  MONTHLY = 'scoreMonthly',
  verifiedClientsMonthly = 'verifiedClientsMonthly',
  verifiedVideosMonthly = 'verifiedVideosMonthly',
  visitsMonthlyTrending = 'visitsMonthlyTrending',
  visitsMonthly = 'visitsMonthly',
  reviewsMonthly = 'reviewsMonthly',
  addedVideosMonthly = 'addedVideosMonthly',
  votesBannersMonthly = 'votesBannersMonthly',
  olympics = 'olympics',
  forumGoodQuestion = 'forumGoodQuestion',
  forumUpVote = 'forumUpVote',
  forumHelpful = 'forumHelpful',
  forumInsightful = 'forumInsightful',
}

export interface TalentListSort {
  type?: TalentSearchSort;
}

export interface OlympicData {
  emoji: string;
  name: string;
  redDiamond: string;
  blueDiamond: string;
  gold: string;
  silver: string;
  simpleVerified: string;
}


export const WALKTHROUGH_ACTION_KEY_STORAGE = 'WTAKS_bf4c8996';
