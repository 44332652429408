import styled, { css, keyframes } from 'styled-components/macro';
import WithTarget from './WithTarget';
import { Title } from './StyledComponents';
import { SmartBannerTypes } from './interface';
import useUser from '../../hooks/useUser';
import { device } from '../../consts/device';
import { GoogleAnalytic } from '../../Utils/ga';
import { useTestimonySmartBanner } from '../PlacementTestimony/useTestimonySmartBanner';
import { useCallback } from 'react';
import { MdClose } from 'react-icons/md';
import useDevice from '../../hooks/useDevice';

interface Props {
  variant: SmartBannerTypes;
}
const SmartBanner: React.FC<Props> = ({ variant }) => {
  const { talentInfo } = useUser();
  const { close, showTestimonySBanner } = useTestimonySmartBanner();
  const { isMobile } = useDevice();

  // const clickTeammateBanner = () => {
  //   GoogleAnalytic.TeammateBannerFChannel();
  // };
  const clickVerifyBanner = () => {
    GoogleAnalytic.VerifyBannerFChannel();
  };
  const clickChangeEmailBanner = () => {
    GoogleAnalytic.ChangeEmailBanner();
  };

  const getSmartContent = useCallback(() => {
    switch (variant) {
      case SmartBannerTypes.COMPLETE_PROFILE:
        if (talentInfo?.id)
          return (
            <WithTarget target={`/talent/profile/${talentInfo.id}`}>
              <Title>Please complete your profile!</Title>
            </WithTarget>
          );
        else return null;
      case SmartBannerTypes.BDGE_LOST_WARNING:
        return (
          <WithTarget target={`/talent/vitrine/${talentInfo?.id}`} className='BDGE_LOST_WARNING'>
            <Title>
              IMPORTANT: Your badge is on hold, due{isMobile && <br />} to a denied client.<br />
              To restore your badge, ask the channel{isMobile && <br />} that denied working with you to{isMobile && <br />} reconsider, or remove their videos from{isMobile && <br />} your portfolio.
            </Title>
          </WithTarget>
        );
      case SmartBannerTypes.ASK_FOR_REVIEW_PANIC:
        return (
          <WithTarget target={`/talent/badges-description`}>
            <Title>IMPORTANT: Ask your clients for a review</Title>
          </WithTarget>
        );
      case SmartBannerTypes.ASK_FOR_REVIEW:
        if (talentInfo?.id)
          return (
            <WithTarget target={`/talent/badges-description`}>
              <Ask2VerifyContainer>
                <Title style={{ marginRight: 0 }}>Ask your clients to confirm your cooperation</Title>
              </Ask2VerifyContainer>
            </WithTarget>
          );
        else return null;
      case SmartBannerTypes.VERIFY_CHANNEL_PANIC:
        return (
          <WithTarget target={`/channel/verified-channels-description`} onClick={clickVerifyBanner}>
            <Title>IMPORTANT: Verify your channel account</Title>
          </WithTarget>
        );
      case SmartBannerTypes.VERIFY_CHANNEL:
        return (
          <WithTarget target={`/channel/verified-channels-description`} onClick={clickVerifyBanner}>
            <Title>Please verify your channel account</Title>
          </WithTarget>
        );
      case SmartBannerTypes.CHANGE_EMAIL:
        return (
          <WithTarget target={`/channel/settings`} onClick={clickChangeEmailBanner}>
            <Title>Please provide your email address</Title>
          </WithTarget>
        );
    }
  }, [variant, talentInfo, isMobile]);


  if (variant === SmartBannerTypes.NONE) return (showTestimonySBanner && false) ? (
    <Container2>
      <WithTarget target={`/recent-placements`} onClick={() => localStorage.setItem('TMPSMRTBNR$', '1')}>
        <Title>Educate others by sharing your <br className='brek' /> placement story!</Title>
      </WithTarget>
      <MdClose className='close-icon' onClick={close} />
    </Container2>
  ) : null;

  return (
    <>
      <Container data-testid='smart-banner-complete-profile' variant={variant} className='smart-banner'>
        {getSmartContent()}
      </Container>
    </>
  );
};
export default SmartBanner;

const animateBackground = keyframes`
  0% {background-position:0% 53%}
  50% {background-position:100% 48%}
  100% {background-position:0% 53%}
`
const Container = styled.section<{ variant?: SmartBannerTypes; }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  height: max-content;
  background: linear-gradient(90deg, #b721ff 4.69%, #21d4fd 100%);
  ${({ variant }) =>
    (
      variant === SmartBannerTypes.VERIFY_CHANNEL_PANIC
      || variant === SmartBannerTypes.ASK_FOR_REVIEW_PANIC
      || variant === SmartBannerTypes.BDGE_LOST_WARNING
    ) && css`
    background: linear-gradient(90deg, var(--color-primary-dark) 4.69%, var(--color-error) 100%);
  `}
  .BDGE_LOST_WARNING {
    padding: 6px 0;
    > div > p {
      font-size: 17px;
    }
    @media ${device.mobileL} {
      padding: 6px 16px;
      > div > p {
        font-size: 15px;
      }
    }
  }
`;
const Container2 = styled.section<{ variant?: SmartBannerTypes; }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  height: max-content;
  justify-content: center;
  gap: 6px;

  background: linear-gradient(270deg, #426a99, #b721ff, #c7148d, #0caad4);
  background-size: 800% 800%;

  ${css`
    animation: ${animateBackground} 12s ease infinite;
  `}

  svg:not(.close-icon) {
    display: none;
  }
  svg.close-icon {
    color: var(--color-white);
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
      color: var(--color-primary-dark);
      transform: scale(1.15)
    }
  }
  > a {
    max-width: calc(var(--max-width-page) - 30px);
    width: calc(100% - 30px) !important;
    p {
      white-space: unset;
      width: 100%;
      margin-right: 0;
      padding-left: 10px;
    }
  }
  .brek {
    display: none;
  }
  @media ${device.mobileL} {
    svg.close-icon {
      margin-right: 10px;
    }
    .brek {
      display: block;
    }
  }
`;
const Ask2VerifyContainer = styled.section`
  display: flex;
  align-items: center;
  > svg.new-verified-icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
  @media ${device.mobileL} {
    > svg.new-verified-icon {
      display: none;
    }
  }
`;

