import { IReviews, TalentListReqFilter, TalentListSort, TalentReportReason, TalentSearchSort, YtVideo } from "../../Interfaces/Talent";
import { ytJobsApi } from "../clients";
import { Add2DiscoveryListRes, AskImportResponse, GetTalentListResponse, GetVouchesListResponse, TCountryCode, TalentVerifiedInfo, TalentYouTubeVideos } from "../interfaces/Talent";
import { getSmartBaseUrl } from "../utils";

///////////Talent///////////////

export const getTalentDetail = async ({
  id,
  filter,
  handle
}: {
  id: string | undefined;
  filter?: string | undefined;
  handle?: string;
}) => {
  if (!id && !handle) throw new Error("Id or Handle not found");
  const { data } = await ytJobsApi.get(`/talents/${handle || id}`, {
    params: {
      filter,
      showAll: true
    }
  });
  if (data?.talent && data?.youtubeVideos) {
    const tempData = {
      ...data.talent,
      ...data,
      youtubeVideos: { ...data?.youtubeVideos }
    };

    if (tempData?.reviews?.length) {
      const reviews = tempData.reviews as IReviews[];
      reviews.sort((a, b) => {
        return getVal(b?.reviewer?.abvSubscribers || '0') - getVal(a?.reviewer?.abvSubscribers || '0');
      })
      tempData.reviews = reviews;
    }

    return tempData;
  }
  return data;
};
export const getTalentActivityBoard = async ({ id }: { id: string | undefined; }) => {
  if (!id) throw new Error("Id not found");
  const { data } = await ytJobsApi.get<Record<string, number>>(`/talents/${id}/recent-activity`);
  return data;
};
export const getTalentTimelineVideoList = async ({ id, year }: { id: string | undefined; year: number }) => {
  if (!id) throw new Error("Id not found");
  const { data } = await ytJobsApi.get<{
    data: YtVideo[]
  }>(`/talents/${id}/time-line?year=${year}`);
  return data;
};
export function getVal(val: string) {
  const multiplier = val[val.length - 1].toLowerCase();
  if (multiplier === "k")
    return parseFloat(val) * 1000;
  else if (multiplier === "m")
    return parseFloat(val) * 1_000_000;
  else if (multiplier === "b")
    return parseFloat(val) * 1_000_000_000;
  else
    return parseFloat(val);
}

export const addTalents2DiscoveryList = async ({
  filter,
}: {
  filter?: TalentListReqFilter;
}): Promise<Add2DiscoveryListRes> => {
  const { data } = await ytJobsApi.post<Add2DiscoveryListRes>('/company/dsicovery-list/add-bulk', {}, {
    params: {
      filter,
    }
  })
  return data;
}

export const getPaginateTalentList = async ({
  limit,
  filter,
  page,
  sort,
  canSearchAdvanced = true,
  month,
  year
}: {
  limit: number;
  filter?: TalentListReqFilter;
  sort?: TalentListSort;
  page: number;
  canSearchAdvanced?: boolean;
  month?: string;
  year?: string;
}): Promise<GetTalentListResponse> => {
  const url = canSearchAdvanced ? "/talents" : '/talents/count';
  let sortObj = !!sort?.type ? sort : undefined
  if (!sortObj && filter?.isBookable) {
    //@ts-ignore
    sortObj = { type: 'isBookable' }
  }
  const { data } = await ytJobsApi.get<GetTalentListResponse>(url, {
    params: {
      limit,
      search: "",
      filter,
      sort: sortObj,
      page,
      month,
      year: !!year ? year : undefined
    },
  });
  return data;
};
export const getPaginateTalentVideoList = async ({
  page,
  limit,
  talentId,
  filter,
  sortBy,
  sortDirection
}: {
  page: number;
  limit: number;
  talentId: string | undefined;
  filter: string | undefined;
  sortBy?: string;
  sortDirection?: string;
}): Promise<TalentYouTubeVideos> => {
  const { data } = await ytJobsApi.get<TalentYouTubeVideos>(
    `/talents/${talentId}/videos?`,
    {
      params: {
        limit,
        search: "",
        page,
        filter,
        sortBy,
        sortDirection,
      },
    }
  );
  return data;
};
export const setVideoListSort = async ({
  sortBy,
  sortDirection
}: {
  sortBy: string;
  sortDirection: string;
}): Promise<{
  success: string;
  message: string;
}> => {
  const { data } = await ytJobsApi.post(
    `/youtube/videos/sort`, {},
    {
      params: {
        sortBy,
        sortDirection,
      },
    }
  );
  return data;
};

export const getTalentReviewByChannel = async (
  talentId: string
): Promise<IReviews> => {
  const { data } = await ytJobsApi.get<IReviews>(
    `/company/made-reviews/${talentId}`
  );
  return data;
};

/////////////////Vouch//////////////

export const getVouchesList = async (
  talentId: string
): Promise<GetVouchesListResponse> => {
  const { data } = await ytJobsApi.get<GetVouchesListResponse>(
    `/talents/${talentId}/vouches`
  );
  return data;
};

export const getVerifiedInfo = async (
  talentId: string
): Promise<TalentVerifiedInfo> => {
  const { data } = await ytJobsApi.get<TalentVerifiedInfo>(`/talents/protected/${talentId}`);
  return data;
};

/////////report////////////
export const reportTalent = async ({
  talentId,
  reason,
  description,
}: {
  talentId: string | undefined;
  reason: TalentReportReason | null;
  description: string;
}) => {
  if (!reason) throw new Error("Reason field should not be empty!");
  if (!talentId) throw new Error("The talent Id field should not be empty!");
  const { data } = await ytJobsApi.post(`/talents/${talentId}/reports`, {
    reason,
    description,
  });
  return data;
};

//
export const resetEmail = async (email: string) => {
  const { data } = await ytJobsApi.post(`/auth/reset-email`, {
    email,
  });
  return data;
};

// email and phone number change and verification
export const sendVerifyNumber = async (phone: string) => {
  const { data } = await ytJobsApi.post(`/auth/user/send-code-to-number`, {
    phone,
  });
  return data;
};
export const verifyPhoneNumber = async (code: string) => {
  const { data } = await ytJobsApi.post(`/auth/user/verify-number`, {
    code,
  });
  return data;
};
export const verifyLocation = async (latitude: number, longitude: number) => {
  const { data } = await ytJobsApi.post(`/auth/user/verify-location`, {
    latitude,
    longitude
  });
  return data;
};
export const getContryCodes = async (): Promise<TCountryCode[]> => {
  const { data } = await ytJobsApi.get<TCountryCode[]>(`/locations/countries`);
  return data.map(d => ({ ...d, query: (d.name.toLowerCase() + d.phoneCode) }));
};

export const verifyTwitter = async () => {
  const { data } = await ytJobsApi.post(`/oauth/twitter/auth-link`, {}, {
    baseURL: getSmartBaseUrl().replace('/api', '')
  });
  return data;
};
export const callbackVerifyTwitter = async (oauth_token: string, oauth_verifier: string) => {
  const { data } = await ytJobsApi.post(`/auth/oauth/twitter/callback`, {}, {
    params: {
      oauth_token,
      oauth_verifier
    }
  });
  return data;
};

export const deleteUserInfo = async (type: 'youtube' | 'twitter' | 'number' | 'location') => {
  const { data } = await ytJobsApi.delete(`/auth/user/${type}`);
  return data;
}
export const deleteUserAccount = async (): Promise<{ success: boolean; message: string; }> => {
  const { data } = await ytJobsApi.post<{ success: boolean; message: string; }>(`/auth/delete-account?agreed=1`);
  return data;
}

// LeaderBoraedApi
export const getLeaderBoardTalents = async ({
  limit,
  sort,
  month,
  year
}: {
  limit: number;
  sort?: TalentListSort;
  month?: string;
  year?: string;
}): Promise<GetTalentListResponse> => {
  const url = sort?.type === TalentSearchSort.olympics ? `/leaderboards/${sort?.type}` : `/leaderboards/${sort?.type}/users`;
  const { data } = await ytJobsApi.get<GetTalentListResponse>(url, {
    params: {
      limit,
      month,
      year: !!year ? year : undefined
    },
  });
  return data;
};
// getTalentStatiscs
export const getTalentStatiscs = async (talentId?: string) => {
  const { data } = await ytJobsApi.get(`/talents/${talentId}/statistics`);
  return data;
};


// get state of multi adding
export const getMultiImportState = async () => {
  const { data } = await ytJobsApi.get<AskImportResponse>(`/profile/import-links`);
  // return {
  //   success: true,
  //   status: ImportStatus.ready,
  //   importLinks: [
  //     { status: ImportLinkStatus.imported, url: 'https://www.youtube.com/watch?v=qPly1M-JEqc', errorMessage: ''},
  //     { status: ImportLinkStatus.imported, url: 'https://www.youtube.com/watch?v=nR9UfOueJPU', errorMessage: ''},
  //     { status: ImportLinkStatus.imported, url: 'https://www.youtube.com/watch?v=K0X5A6aURms', errorMessage: ''},
  //     { status: ImportLinkStatus.imported, url: 'https://www.youtube.com/watch?v=JxdcuHXn9dc', errorMessage: ''},
  //     { status: ImportLinkStatus.error, url: 'https://www.youtube.com/watch?v=qPly1M-JEqc', errorMessage: ''},
  //     { status: ImportLinkStatus.error, url: 'https://www.youtube.com/watch?v=YUcLPuFk9FM', errorMessage: ''},
  //     { status: ImportLinkStatus.error, url: 'https://www.youtube.com/watch?v=rb0EhmyQ0aM', errorMessage: ''},
  //   ]
  // } as AskImportResponse;
  return data;
};
export const multiImportUrls = async (urls: string[]) => {
  const { data } = await ytJobsApi.post<AskImportResponse>(`/profile/import-links`, { urls });
  return data;
};

// get video detail on demand
export async function getVideoDetailOnDemand<T = unknown>(url: string): Promise<T> {
  const { data } = await ytJobsApi.get(`/youtube-videos/fetch?url=${url}`);
  return data;
}

// get talent own hidden reviews
export const getHiddenReviewListList = async () => {
  const { data } = await ytJobsApi.get<IReviews[]>(
    `/profile/reviews/hidden`
  );
  return data;
}


// get talent own hidden reviews
export const getBannerVotedStatus = async (userId: string | undefined) => {
  const { data } = await ytJobsApi.get<{ voted: boolean }>(
    `/votes/hot-banner/${userId}`
  );
  return data;
}
export const hotBannerVote = async (userId: string | undefined) => {
  const { data } = await ytJobsApi.post<{ success: boolean }>(
    `/votes/hot-banner/${userId}`
  );
  return data;
}

export const makeOutreach = async (subject: string, message: string, channelId: string, attachments?: string[]) => {
  const body = {
    subject,
    message
  }
  if (!!attachments?.length) {
    //@ts-ignore
    body['attachments'] = attachments
  }
  const { data } = await ytJobsApi.post<{ success: boolean; error: string; }>(`/companies/${channelId}/outreach`, body);
  return data;
};