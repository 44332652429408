import { ExpertiseID } from "../../Interfaces";
import { ytJobsApi } from "../clients";


export type FunctionAreaItem = {
  id: ExpertiseID;
  name: string;
}

export const getFunctionalAreas = async () => {
  const { data } = await ytJobsApi.get<FunctionAreaItem[]>(`/functional-areas`);
  return data;
};


export const STATIC_FN_AREAS = [
  {
    "id": "1",
    "name": "Video Editor"
  },
  {
    "id": "2",
    "name": "Creative Director"
  },
  {
    "id": "3",
    "name": "Thumbnail Designer"
  },
  {
    "id": "4",
    "name": "Channel Manager"
  },
  {
    "id": "6",
    "name": "YouTube Strategist"
  },
  {
    "id": "8",
    "name": "Scriptwriter"
  },
  {
    "id": "9",
    "name": "Youtube Producer"
  },
  // {
  //   "id": "10",
  //   "name": "Voice Over"
  // },
  // {
  //   "id": "11",
  //   "name": "I am hiring for a different position"
  // }
] as unknown as FunctionAreaItem[]

export const CLIENT_SOURCES = [
  'YT Jobs',
  'LinkedIn',
  'X (Twitter)',
  'Upwork',
  'Fiverr',
]